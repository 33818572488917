<template>
  <div class="col-md-3 sideBar">
    <div class="holiday-rht" v-if="data.length > 0">
      <h2>{{ $t('product-page.location') }}</h2>
      <h3>
        <i class="fas fa-map-marker-alt"></i>
        <span>{{ data[0].address ? data[0].address : data[0].cityName }}</span>
      </h3>
      <div class="map-hldr">
        <Map
          :width="'600'"
          :height="'450'"
          :address="data[0].cityName + ' ' + (packageType === 'Organize_tour_packages' ? '' : data[0].hotelName)"
        />
      </div>
      <div class="remarks" v-if="packageType === 'vacation_pack' && remarks && currentPage !== 'booking'">
        <h2>{{ $t('product-page.remarks') }}</h2>
        <p v-html="translatedRemarks"></p>
      </div>
      <div class="remarks" v-if="packageType === 'Organize_tour_packages' && currentPage !== 'booking'">
        <h2>{{ $t('product-page.includes') }}</h2>
        <div v-html="translatedItemsInclude"></div>
        <h2 class="mt-3">{{ $t('product-page.notIncludes') }}</h2>
        <div v-html="translatedItemsNotInclude"></div>
        <h2 v-if="optional!==''" class="mt-3 d-flex">{{ $t('product-page.importantNotes') }}<p class="mr-2">💡</p></h2>
        <div v-if="optional!==''" v-html="translatedOptional"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';
import { translateFormattedText } from '@/utils/translate';

export default {
  mixins: [imageUrlMixin],
  components: {
    Map: () => import('@/components/productPage/atoms/GoogleMap'),
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    packageType: {
      type: String,
      default: '',
    },
    remarks: {
      type: String,
      default: '',
    },
    includes: {
      type: Object,
      defatult: null,
    },
  },
  data() {
    return {
      translatedRemarks: '',
      translatedItemsInclude: '',
      translatedItemsNotInclude: '',
      translatedOptional: '',
    };
  },
  computed: {
    ...mapGetters({
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      currentPage: 'GET_CURRENT_PAGE',
      lang: 'GET_LANGUAGE',
    }),
    itemsInclude() {
      if (!this.includes) return '';
      return this.includes.include.split('\n').map((item) => `<div class="d-flex p-0"><p><img src="${this.speedSizeDomain}/assets/img/check-blue.png" class="ml-2 check" alt="check-blue" width="15" height="15"/></p> <p>${item}</p></div>`).join('');
    },
    itemsNotInclude() {
      if (!this.includes) return '';
      return this.includes.notInclude.split('\n').map((item) => {
        if (item.indexOf('•\t') > -1) {
          const strItem = item.replace('•\t', '');
          return `<div class="d-flex p-0"><p><img src="${this.speedSizeDomain}/assets/img/close_red.png" class="ml-2 close" alt="close-red" width="15" height="15"/></p> <p>${strItem}</p></div>`;
        } else {
          return `<div class="d-flex p-0"><p><img src="${this.speedSizeDomain}/assets/img/close_red.png" class="ml-2 close" alt="close-red" width="15" height="15"/></p> <p>${item}</p></div>`;
        }
      }).join('');
    },
    optional() {
      if (!this.includes || this.includes.optional === '') return '';
      return this.includes.optional.split('•\t').map((item) => {
        const strItem = item.replace('•\t', '');
        return strItem.length > 0 ? `<div class="d-flex p-0"><p class="ml-2"> 💡 </p> <p> ${strItem} </p></div>` : '';
      }).join('');
    },
  },
  watch: {
    async lang() {
      this.translatedRemarks = await translateFormattedText(this.remarks, this.lang);
      this.translatedItemsInclude = await translateFormattedText(this.itemsInclude, this.lang);
      this.translatedItemsNotInclude = await translateFormattedText(this.itemsNotInclude, this.lang);
      this.translatedOptional = await translateFormattedText(this.optional, this.lang);
    },
    async remarks() {
      this.translatedRemarks = await translateFormattedText(this.remarks, this.lang);
    },
    async itemsInclude() {
      this.translatedItemsInclude = await translateFormattedText(this.itemsInclude, this.lang);
    },
    async itemsNotInclude() {
      this.translatedItemsNotInclude = await translateFormattedText(this.itemsNotInclude, this.lang);
    },
    async optional() {
      this.translatedOptional = await translateFormattedText(this.optional, this.lang);
    },
  },
  methods: {
    splitParagraph(str) {
      return str
        .split(':')
        .join(':<br>')
        .split('.')
        .join('.<br>');
    },
  },
};
</script>

<style scope>
  .check {
    width: 25px;
    height: auto;
  }
  .close {
    margin-top: 10px;
    width: 25px;
    height: auto;
    opacity: 1 !important;
  }
</style>
